@import url('https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Borel&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  background-color: #fff;
}

/* @import url('https://fonts.cdnfonts.com/css/carena');
    
:root{
  --font-family:'Carena', sans-serif;
  --green: #0f6804;
  --yellow: #ffc900;
  --black: #000009;
  --blue: #000069;
  --white: #fff;
}

.section__padding{
  padding: 2rem 3rem;
}

.section__padding__footer{
  padding: 3rem 3rem 0;
}
.section__margin{
  margin: 2rem 3rem;
}

@media screen and (max-width: 700px)  {

  .section__padding{
    padding: 2rem;
  }
  .section__margin{
    margin: 4rem;
  }
}

@media screen and (max-width:550px){
  .section__margin{
    margin:4rem 2rem;
  }

  .section__padding{
    padding: 4rem 2rem;
  }
} */