.ksm__brand {
    max-width: 100%;
  
}

/* .ksm__brand h1 {
    font-size: 25px;
    text-align: center;
    margin-bottom: 2rem;
} */

/* .slick-slide img {
    display: block;
    margin: 0 auto;
    width: 80%;
    max-width: 250px;
    height: auto;
} */

@media screen and(max-width:1050px) {

    .ksm__brand h1 {
        font-size: 23px;
    }
}

@media screen and (max-width:700px){
    .ksm__brand h1{
        font-size: 1.5rem;
    }
}

@media screen and (max-width:440px){
    .ksm__brand h1{
        font-size: 20px;
    }
}